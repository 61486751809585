.Button {
    background-color: transparent;
    border: none;
    color: white;
    outline: none;
    cursor: pointer;
    font: inherit;
    padding: 10px;
    margin: 10px;
    font-weight: bold;
    box-sizing: border-box;
}

.Success {
    color: #5C9210;
}

.Danger {
    color: #944317;
}

button:disabled {
    color: #aaa;
    cursor: not-allowed;
}